
import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './Zhubnout_bez_sportu.png';
import styles from './post-grid.module.css';

const JidelnicekHubnutiBezSportu = () => {
    return (
      <div className={styles.wrapper}>
        <SEO
          title={"Je možné zhubnout bez sportu? | NutritionPro"}
          description={"Hubnutí bez sportu? Ano, je to možné, ale má to své nevýhody. Pokud chcete hubnout bez sportu, potřebujete především kvalitně sestavený fitness jídelníček."}
        />
        <Container>
          <Breadcrumbs style={{ margin: "24px 0" }}>
            <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Je možné zhubnout bez sportu?
            </BreadcrumbsItem>
          </Breadcrumbs>
        </Container>
        <Container>
          <div className={styles.postGrid}>
            <div>
              <Hero
                title="Je možné zhubnout bez sportu? Ano, ale má to své nevýhody"
                date="16.05.22"
              />
              <div>
                <p className={styles.postText}>
                Hubnout a přitom nesportovat? To je sen mnoha žen a mužů, kteří ke sportu nemají vztah. Dobrou správou je, že takové hubnutí je možné, nicméně má své nevýhody. <b>Hubnutí bez sportu rozhodně není efektivní ani rychlé</b>, protože pokud chcete zhubnout rychle, musíte zařadit mezi své denní aktivity také sport. Jak tedy zhubnout bez sportu? 
                </p>
                <img
                  src={img1}
                  className={styles.postImg}
                  style={{ marginBottom: "40px" }}
                  alt="img1"
                />                
                <h5 className={styles.postTitle}>
                Hubnutí bez cvičení je plné kompromisů
                </h5>
                <p className={styles.postText}>
                <b>Zdravé a vyvážené hubnutí jde ruku v ruce se sportem</b>, nebo alespoň s pohybem. Pokud se v práci hýbete, můžete tento pohyb také zahrnout do svých hubnoucích plánů. Ovšem pokud jen sedíte v kanceláři, je to úplně jiná situace. Když dosáhnete kalorického deficitu, samozřejmě budete hubnout. Problém ale je, že <b>bez cvičení nebo sportu své tělo nezpevňujete, nebudujete ani svalovou hmotu</b>. Výsledkem je pak zbytečně <b>ochablé tělo</b> – navíc platí, že čím více svalů máte, tím lépe tělo spaluje tuky.
                </p>
                <h5 className={styles.postTitle}>
                Upravte si jídelníček na hubnutí
                </h5>
                <p className={styles.postText}>
                <b>Základem každého hubnutí je jídelníček</b>. Ať už sportujete nebo ne, bez fitness jídelníčku to zkrátka nikdy nebude ono. Proto doporučujeme začít zdravým hubnutím s <a href='https://nutritionpro.cz/'>úpravou jídelníčku</a>. Jak ideálně upravit jídelníček? <b>Důležitý je poměr živin, jako jsou bílkoviny, sacharidy a tuky</b>. Připravili jsme pro vás článek, kde se dozvíte více o tom, <a href='https://nutritionpro.cz/blog/zdrave-udrzitelne-hubnuti/'>jak na zdravé a udržitelné hubnutí</a>.
                </p>
                <p className={styles.postText}>
                Nicméně základem jídelníčku na hubnutí je <b>větší střídmost v jídle</b>. Jezte tak, abyste byli sytí, ale nepřehánějte to. Zároveň zařaďte <b>kvalitní bílkoviny a tuky</b>, protože vás zasytí na delší dobu. <b>Sacharidy by měly být komplexní</b>, ne pouze jednoduché cukry. Stejně tak je důležité si začít vhodná jídla vařit. Vyhněte se polotovarům a průmyslově zpracovaným jídlům, které většinou nemají dobré výživové hodnoty.
                </p>
                <h5 className={styles.postTitle}>
                Zkuste hubnutí bez sportu s NutritionPro
                </h5>
                <p className={styles.postText}>
                Pokud sportovat nechcete nebo vám v tom brání například zdravotní omezení, je důležité <b>zaměřit se o to více na vyvážený fitness jídelníček</b>, se kterým zhubnete. Proto doporučujeme <a href='https://nutritionpro.cz/products/product-1/'>hubnoucí program </a>vytvořený <b>na míru naší nutriční specialistkou</b>. Nebudete se tak vůbec o nic starat. Přesně spočítáme potřebný kalorický deficit, a navíc vám připravíme i jídlo. <b>Pohodlnější, a přitom tak zdravé stravování jste ještě neviděli</b>.
                </p>
              </div>
            </div>
            <PostSidebar />
          </div>
        </Container>
      </div>
    )
  }
  
  export default JidelnicekHubnutiBezSportu